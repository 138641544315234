<template>
	<div class="sidebar-right-meeting">
		<div class="header-sidebar">
			<div class="title-sidebar">{{ $t("meeting_session.comments_decision") }}</div>
		</div>

		<div class="list-content comments" ref="commentSection">
			<div class="comment-item" v-for="comment in comments">
				<div class="header-comment">
					<div class="author">
						<img class="avatar" :src="comment.author.avatar" />
						<span class="name">{{ comment.author.name }}</span>
					</div>
					<div class="text">
						<div class="edited" v-if="comment.created_at != comment.updated_at || comment.edited">({{ $t("meeting_session.edited") }})</div>
						<div class="date">{{ moment(comment.created_at).fromNow() }}</div>
					</div>
					<div class="actions" v-if="comment.author.id == ($auth.check() ? $auth.user().id : '')">
						<div class="edit" @click="editComment(comment)">
							<icon-edit />
						</div>
						<div class="delete" @click="deleteComment(comment)">
							<icon-trash />
						</div>
					</div>
				</div>
				<div class="edited-input-box" v-if="comment.id == editedCommentId">
					<quill-editor class="editor-tbf h-100" ref="myQuillEditor" v-model="editedComment" :options="editorOption" />
				</div>
				<div class="content-comment" v-html="comment.body" v-else></div>
				<div class="footer-comment">
					<div class="step">
						<div class="icon">
							<icon-project v-if="comment.step_type.type == 'project'" />
							<icon-objective v-else-if="comment.step_type.type == 'objective'" />
							<icon-key-result v-else-if="comment.step_type.type == 'key_result'" />
							<icon-task v-else-if="comment.step_type.type == 'task'" />
							<icon-custom v-else-if="comment.step_type && comment.step_type.type == 'custom'" />
							<icon-check-list v-else-if="comment.step_type && comment.step_type.type == 'today_planning'" />
							<icon-check-list v-else-if="comment.step_type && comment.step_type.type == 'last_meeting'" />
						</div>
						<div class="name">
							<template v-if="comment.step_type.type == 'project'" v-html="comment.project.name"></template>
							<template v-else-if="comment.step_type.type == 'objective'">{{ comment.objective.name }}</template>
							<template v-else-if="comment.step_type.type == 'key_result'">{{ comment.key_result.name }}</template>
							<template v-else-if="comment.step_type.type == 'task'">{{ comment.task.name }}</template>
							<template v-else-if="comment.step_type.type == 'custom'">{{ comment.step_type.topic_name }}</template>
							<template v-else-if="comment.step_type.type == 'today_planning'">{{ $t("meeting_session.today_planning") }}</template>
							<template v-else-if="comment.step_type.type == 'last_meeting'">{{ $t("meeting_session.last_meeting") }}</template>
						</div>
					</div>
					<div class="update" v-if="comment.id == editedCommentId">
						<button class="btn-tbf blue" @click="updateComment(comment)">
							<div class="loader-spin" v-if="loadingUpdateComment"></div>
							<div class="text" v-else>{{ $t("general.update") }}</div>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="add-comment" v-if="$auth.check()">
			<quill-editor class="editor-tbf h-100" ref="myQuillEditor" v-model="newComment" :options="editorOption" />
			<div class="footer">
				<div class="step">
					<div class="icon">
						<icon-project v-if="step.key === 'project'" />
						<icon-objective v-else-if="step.key === 'objective'" />
						<icon-key-result v-else-if="step.key === 'key_result'" />
						<icon-task v-else-if="step.key === 'task'" />
						<icon-custom v-else-if="step.key === 'custom'" />
						<icon-check-list v-else-if="step.key === 'planning_the_day' || step.key === 'last_meeting'" />
					</div>
					<div class="name" v-if="step.key === 'planning_the_day'">{{ $t("meeting_session.today_planning") }}</div>
					<div class="name" v-else-if="step.key === 'last_meeting'">{{ $t("meeting_session.last_meeting") }}</div>
					<!-- <div class="name" v-else-if="step.key === 'custom'">{{ step.topic_name }}</div> -->
					<div class="name" v-else>{{ step.name }}</div>
				</div>
				<div class="action-store">
					<button class="btn-tbf blue" @click="storeComment">
						<div class="loader-spin" v-if="loadingStoreComment"></div>
						<div class="text" v-else>{{ $t("general.add") }}</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconArrow from "@/components/Icons/Arrow";
import IconProject from "@/components/Icons/Projects";
import IconObjective from "@/components/Icons/Objectives";
import IconTask from "@/components/Icons/Task";
import IconCheckList from "@/components/Icons/CheckList";
import IconCustom from "@/components/Icons/Edit";
import IconKeyResult from "@/components/Icons/KeyResult";
import IconEdit from "@/components/Icons/Edit";
import IconTrash from "@/components/Icons/Trash";
import { debounce } from "debounce";

import "quill-mention";

export default {
	components: {
		IconArrow,
		IconProject,
		IconObjective,
		IconTask,
		IconCheckList,
		IconCustom,
		IconKeyResult,
		IconEdit,
		IconTrash,
	},
	props: {
		step: {
			type: Object,
			required: false,
		},
		comments: {
			type: Array,
			required: true,
		},
	},
	watch: {
		comments() {
			setTimeout(() => {
				this.scrollToBottom();
			}, 200);
		},
	},
	created() {
		this.handleDebounceTopic = debounce(() => {
			this.updateTopic();
		}, 1000);
	},
	data() {
		return {
			isLoading: true,
			newComment: "",
			editedComment: "",
			editedCommentId: "",
			editorOption: {
				placeholder: "Adauga comentariu...",
				theme: "bubble",
				modules: {
					toolbar: [["bold", "italic", "code-block", "link"], [{ list: "ordered" }, { list: "bullet" }, { color: [] }, { background: [] }], ["clean"]],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "announces");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
					mention: {
						allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
						mentionDenotationChars: ["@"],
						source: async (searchTerm, renderList) => {
							const matchedPeople = await this.suggestPeople(searchTerm);
							renderList(matchedPeople, searchTerm);
						},
						dataAttributes: ["id", "name"],
					},
				},
			},
			optionsUsers: [],
			activeTopic: "",
			loadingStoreComment: false,
			loadingUpdateComment: false,
		};
	},
	async mounted() {
		if (this.$auth.check()) {
			await this.getUsers();
		}

		setTimeout(() => {
			this.scrollToBottom();
		}, 0);

		this.$root.$on("updateCommentMeeting", (commentData) => {
			this.actionCommentWS(commentData);
		});
	},
	beforeDestroy() {
		this.$root.$off("updateCommentMeeting");
	},
	methods: {
		async getUsers() {
			await axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: { users: true } }).then(({ data }) => {
				this.optionsUsers = data.data.users;
			});
		},
		async suggestPeople(searchTerm) {
			return this.optionsUsers
				.filter((user) => user.name.toUpperCase().includes(searchTerm.toUpperCase()))
				.map((el) => {
					return { id: el.id, value: el.name };
				});
		},
		storeComment() {
			this.loadingStoreComment = true;

			if (this.newComment === "") {
				this.loadingStoreComment = false;
				return;
			}

			var formData = {
				body: this.newComment,
				meeting_id: this.$parent.meetingLog.id,
				step_type: { type: this.step.key },
			};

			switch (this.step.key) {
				case "project":
					formData.project_id = this.step.id;
					break;
				case "objective":
					formData.objective_id = this.step.id;
					formData.objective_name = this.step.name;
					break;
				case "key_result":
					formData.key_result_id = this.step.id;
					formData.key_result_name = this.step.name;
					formData.key_result_end_date = this.step.end_date;
					formData.key_result_progress = this.step.percent;
					formData.objective_name = this.step.objective_name;
					break;
				case "task":
					formData.task_id = this.step.id;
					break;
				case "custom":
					formData.step_type.step_uuid = this.step.uuid;
					formData.step_type.topic_name = this.step.name;
					break;
				case "planning_the_day":
					formData.step_type = {
						type: "today_planning",
					};
					break;
				case "last_meeting":
					formData.step_type = {
						type: "last_meeting",
					};
					break;
			}

			axios
				.post(`/comments/store`, formData)
				.then(({ data }) => {
					this.newComment = "";
					this.$refs.myQuillEditor.quill.setContents([]);
					this.comments.push(data.data);
				})
				.finally(() => {
					this.loadingStoreComment = false;
				});
		},
		scrollToBottom() {
			const commentSection = this.$refs.commentSection;
			commentSection.scrollTop = commentSection.scrollHeight;
		},
		deleteComment(comment) {
			axios.delete(`/comments/${comment.id}`).then(() => {
				const indexToRemove = this.comments.findIndex((el) => el.id == comment.id);
				this.comments.splice(indexToRemove, 1);
			});
		},
		editComment(comment) {
			this.editedCommentId = comment.id;
			this.editedComment = comment.body;
		},
		updateComment(comment) {
			this.loadingUpdateComment = true;

			var formData = {
				body: this.editedComment,
			};

			axios.patch(`/comments/${comment.id}`, formData).then(({ data }) => {
				this.loadingUpdateComment = false;
				this.editedComment = "";
				this.editedCommentId = "";

				const commentIndex = this.comments.findIndex((el) => el.id == comment.id);
				this.comments[commentIndex].body = data.data.body;
				this.comments[commentIndex].updated_at = data.data.updated_at;
			});
		},
		actionCommentWS(comment) {
			if (comment.action == "update") {
				const foundCommentIndex = this.comments.findIndex((el) => el.id == comment.id);
				this.comments[foundCommentIndex].body = comment.body;
				this.comments[foundCommentIndex].edited = true;
			} else if (comment.action == "destroy") {
				const indexToRemove = this.comments.findIndex((el) => el.id == comment.id);
				this.comments.splice(indexToRemove, 1);
			}
		},
	},
};
</script>
